import React, { useEffect, useState } from "react";
import TopicNav from "./TopicNav";
import TopicContent from "./TopicContent";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { getI18n, useTranslation } from "react-i18next";

function Topic() {
    const { t } = useTranslation();
    const [topic, setTopic] = useState(null);
    const [topicTree, setTopicTree] = useState(null);
    const params = useParams();
    const slugPath = params["*"];
    const navigate = useNavigate();


    useEffect(() => {
        const GetTopic = async () => {
            try {
                const response = await axiosInstance.get(`/topic/${getI18n().language}/${slugPath}`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setTopic(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to load topic content."));
            }
        };

        const GetTopicTree = async () => {
            try {
                const response = await axiosInstance.get(`/topic/${getI18n().language}/tree/${slugPath}`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setTopicTree(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to load navigation."));
            }
        };

        if (slugPath) {
            GetTopic();
            GetTopicTree();
        }
    }, [slugPath, t]);

    useEffect(() => {
        if (topic && topic.data.content.length === 0 && topicTree && topicTree.child && topicTree.child.length > 0) {
            const findFirstPage = (tree) => {
                if (tree.child){
                    for (const node of tree.child) {
                        if (node.topic.topic.type === "page") {
                            return node.topic;
                        }
                        if (node.child && node.child.length > 0) {
                            const found = findFirstPage(node.child);
                            if (found) return found;
                        }
                    }
                }

                return null;
            };

            const firstPage = findFirstPage(topicTree);
            if (firstPage) {
                navigate(`/topic/${firstPage.topic.slug_path}`);
            }
        }
    }, [topic, topicTree]);

    return (
        <div>
            {topicTree &&
                <TopicNav tree={topicTree} />
            }
            <div className="container my-4">
                <div>
                    {topic ? (
                        <TopicContent topic={topic} />
                    ) : (
                        <p>{t("Loading content...")}</p>
                    )}
                </div>
            </div>
        </div>

    );
}

export default Topic;
