import 'bootstrap/dist/css/bootstrap.min.css';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const Marriage = () => {
    const tg = window.Telegram.WebApp;
    const [marriageId, setMarriageId] = useState(null);
    const [marriage, setMarriage] = useState(null);
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [boostCooldown, setBoostCooldown] = useState(false);
    const [cooldownTime, setCooldownTime] = useState(null);
    const [boostPrice, setBoostPrice] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        const startParam = tg.initDataUnsafe.start_param;
        setMarriageId(startParam);
    },[]);

    useEffect(() => {
        const getMarriageData = async () => {
            try {
                const response = await axiosInstance.get(`/user/marriage/${marriageId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null) {
                    setMarriage(response.data.marriage);
                    setEntities(response.data.entities || []);

                    // Check if cooldown exists in response
                    if (response.data.marriage.next_boost_available_at) {
                        const nextBoostTime = new Date(response.data.marriage.next_boost_available_at);
                        const now = new Date();

                        if (nextBoostTime > now) {
                            setBoostCooldown(true);
                            setCooldownTime(nextBoostTime);
                        } else {
                            setBoostCooldown(false);
                            setCooldownTime(null);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Unable to get information about marriage."));
            } finally {
                setLoading(false);
            }
        };

        const getBoostPrice = async () => {
            try {
                const response = await axiosInstance.get(`/user/marriage/info/boost/price`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null) {
                    setBoostPrice(response.data.price)
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Unable to get information about boost price."));
            } finally {
                setLoading(false);
            }
        };


        if (marriageId !== null) {
            getMarriageData();
            getBoostPrice();
        }
    }, [marriageId, t]);

    const handleBoostMarriage = async () => {
        try {
            const response = await axiosInstance.post(`/user/marriage/${marriageId}/boost`, {for_payment: boostCooldown}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null) {
                setMarriage(response.data.marriage);

                // If there's a new entity, add it to the entities array
                if (response.data.new_entity) {
                    setEntities(prevEntities => [...prevEntities, response.data.new_entity]);
                    toast.success(t("Marriage boosted! You received a new entity!"));
                } else {
                    toast.success(t("Marriage boosted!"));
                }

                // Set cooldown if provided in response
                if (response.data.marriage.next_boost_available_at) {
                    const nextBoostTime = new Date(response.data.marriage.next_boost_available_at);
                    setBoostCooldown(true);
                    setCooldownTime(nextBoostTime);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(t("Failed to boost marriage."));
        }
    };

    const formatCooldownTime = () => {
        if (!cooldownTime) return '';

        const now = new Date();
        const diffMs = cooldownTime - now;

        // If the cooldown has already passed
        if (diffMs <= 0) {
            setBoostCooldown(false);
            return '';
        }

        const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

        if (diffHrs > 0) {
            return `${diffHrs}${t("h")} ${diffMins}${t("m")}`;
        } else {
            return `${diffMins}${t("m")}`;
        }
    };

    const calculateProgress = () => {
        if (!marriage) return 0;
        const { marriage_exp, next_level_required_exp } = marriage;
        return (marriage_exp / next_level_required_exp) * 100;
    };

    // Group entities by type
    const groupEntitiesByType = () => {
        const grouped = {};

        entities.forEach(entity => {
            const type = entity.marriage_entity_entry.type;
            if (!grouped[type]) {
                grouped[type] = [];
            }
            grouped[type].push(entity);
        });

        return grouped;
    };

    // Get translated type name
    const getTypeLabel = (type) => {
        switch (type) {
            case 'child':
                return t("Children");
            case 'pet':
                return t("Pets");
            case 'apartment':
                return t("Apartment");
            case 'transport':
                return t("Transport");
            case 'garden':
                return t("Garden");
            default:
                return t("Other");
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "300px" }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">{t("Loading...")}</span>
                </div>
            </div>
        );
    }

    if (!marriage) {
        return (
            <div className="text-center p-4">
                <div className="alert alert-warning">
                    {t("Marriage information not found")}
                </div>
            </div>
        );
    }

    const groupedEntities = groupEntitiesByType();
    const entityTypes = Object.keys(groupedEntities);

    return (
        <div className="w-100 m-0 d-flex flex-column">
            <div id="marriage-info-block" className="">
                <div className="bg-secondary p-2">
                    <div className="row m-0">
                        <div className="col-12 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 512 512">
                                <path d="M216.5,45.1c-31.3,0-58.6,21.8-66.3,52.2c-7.7-30.4-35-52.2-66.3-52.2c-37.9,0-68.7,30.8-68.7,68.7
    c0,94.2,116.5,170.3,134.9,170.3c18.5,0,134.9-76.1,134.9-170.3C284.9,75.9,254.2,45.1,216.5,45.1z" fill="#ff5a79"/>
                                <path d="M428.5,45.1c-31.3,0-58.6,21.8-66.3,52.2c-7.7-30.4-35-52.2-66.3-52.2c-37.9,0-68.7,30.8-68.7,68.7
    c0,94.2,116.5,170.3,134.9,170.3c18.5,0,134.9-76.1,134.9-170.3C496.9,75.9,466.2,45.1,428.5,45.1z" fill="#ffffff"/>
                                <path d="M257.5,250 C270,270 315,280 315,280 C315,280 242,300 257.5,250z" fill="#ff5a79" opacity="0.7" />
                            </svg>
                        </div>
                        <div className="col-12 text-center">
                            <span className="fs-2">
                                {marriage.first_user.first_name} & {marriage.second_user.first_name}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <small className="hint-text">
                                {t("Created at")} {new Date(marriage.created_at).toLocaleDateString()}
                            </small>
                        </div>
                    </div>
                </div>

                <div className="d-flex p-1 pb-0">
                    <div className="col-6 p-1">
                        <div className="w-100 p-1 pb-3 pt-3 bg-secondary text-center rounded-2">
                            <span className="fs-4"><small className="fs-6">👑</small> {t("Level")} {marriage.marriage_level}</span>
                        </div>
                    </div>
                    <div className="col-6 p-1">
                        <div className="w-100 p-1 pb-3 pt-3 bg-secondary text-center rounded-2">
                            <span className="fs-4"><small className="fs-6">❤️</small> {marriage.marriage_exp}/{marriage.next_level_required_exp}</span>
                        </div>
                    </div>
                </div>

                <div className="p-1 pt-0">
                    <div className="progress" style={{ height: "25px" }}>
                        <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: `${calculateProgress()}%` }}
                            aria-valuenow={marriage.marriage_exp}
                            aria-valuemin="0"
                            aria-valuemax={marriage.next_level_required_exp}
                        >
                            {Math.round(calculateProgress())}%
                        </div>
                    </div>
                </div>

                <div className="p-1 pt-1">
                    <button
                        className="btn btn-danger w-100"
                        onClick={handleBoostMarriage}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : boostCooldown ? (
                            <>⏱️ {t("Available in")} {formatCooldownTime()} ⏱️({boostPrice}{t("coins")})</>
                        ) : (
                            <>❤️ {t("Boost Marriage")} ❤️</>
                        )}
                    </button>
                </div>

                <div className="p-1 pt-2">
                    <div className="bg-secondary p-2 rounded-2">
                        <h5 className="text-center">{t("Marriage Assets")}</h5>

                        {entities && entities.length > 0 ? (
                            <div>
                                {entityTypes.map(type => (
                                    <div key={type} className="mb-3">
                                        <h6 className="border-bottom pb-1">{getTypeLabel(type)}:</h6>
                                        <div className="d-flex flex-wrap">
                                            {groupedEntities[type].map((entity, index) => (
                                                <div key={index} className="pe-2 pb-2">
                                                    <span title={entity.marriage_entity_entry.type}>
                                                        {t(entity.marriage_entity_entry.name)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="text-center p-3">
                                <p>{t("No assets yet. Boost your marriage to get some!")}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="d-flex p-1 pt-2">
                    <div className="col-6 p-1">
                        <div className="w-100 p-2 bg-secondary text-center rounded-2">
                            <span className="fs-5">{marriage.first_user.first_name}</span>
                            <div className="small">
                                {marriage.first_user.username ? "@" + marriage.first_user.username : "id:" + marriage.first_user.user_id}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-1">
                        <div className="w-100 p-2 bg-secondary text-center rounded-2">
                            <span className="fs-5">{marriage.second_user.first_name}</span>
                            <div className="small">
                                {marriage.second_user.username ? "@" + marriage.second_user.username : "id:" + marriage.second_user.user_id}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Marriage;